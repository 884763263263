import {UserInfo} from '@/types/User'
import {getClientSideCookie, removeClientSideCookie, setClientSideCookie} from './helpers'

/**
 * Retrieves and parses the authentication cookie.
 * @returns {Object|null} The parsed authentication information if the cookie exists, or null if it doesn't.
 */
export const handleGetAuthCookie = (): UserInfo | null => {
  const authInfo = getClientSideCookie('authInfo')

  if (!authInfo) return null

  return JSON.parse(decodeURIComponent(authInfo))
}
/**
 * Sets the authentication cookie with the provided user information.
 * @param {UserInfo} data - The user information to be stored in the cookie.
 */
export const handleSetAuthCookie = (data: UserInfo) => {
  setClientSideCookie('authInfo', JSON.stringify(data))
}

/**
 * Removes the authentication cookie.
 */
export const handleRemoveAuthCookie = () => {
  removeClientSideCookie('authInfo')
}

/**
 * Checks the authentication status of the user.
 * @returns {Object|boolean} An object containing the token and login status if authenticated, or false if not authenticated.
 * @property {string} token - The authentication token.
 * @property {boolean} isLogin - Indicates whether the user is logged in.
 */
export const handleCheckAuthStatus = (): {token: string; isLogin: boolean} => {
  const authInfo = handleGetAuthCookie()

  return authInfo ? {token: authInfo.token, isLogin: true} : {token: '', isLogin: false}
}

/**
 * Retrieves and parses the authentication cookie.
 * @returns {string|null} it returns uuid if there is any or null if its undefined.
 */
export const handleGetUUID = (): string | null => {
  const uuid = getClientSideCookie('uuid')

  if (!uuid) return null

  return uuid || ''
}
/**
 * Sets the uuid cookie with the provided user information.
 * @param {string} data - The user information to be stored in the cookie.
 */
export const handleSetUUID = (uuid: string) => {
  if (!uuid) throw new Error('uuid is required')
  setClientSideCookie('uuid', uuid)
}

/**
 * Removes the authentication cookie.
 */
export const handleRemoveUUID = () => {
  removeClientSideCookie('uuid')
}
